import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  embed: {
    position: "relative",
    width: "100%",
    paddingTop: "65%",
    "& iframe": {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
    },
  },
}));

function EmbedCode({ code }) {
  const classes = useStyles();
  return (
    <div
      className={`${classes.embed} embed`}
      dangerouslySetInnerHTML={{ __html: code }}
    ></div>
  );
}

export default EmbedCode;
