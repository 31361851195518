import React from "react";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& a": {
      color: theme.palette.link.main,
      textDecoration: "none",
      display: "inline-block",
      alignItems: "center",
      "& svg": {
        display: "inline-block",
        verticalAlign: "middle",
      },
      "& span": {
        display: "inline-block",
        paddingLeft: theme.spacing(1),
        fontSize: "1.1em",
        verticalAlign: "middle",
      },
      "&:hover, &:active, &:focus": {
        color: theme.palette.link.hover,
        "& span": {
          textDecoration: "underline",
        },
      },
    },
  },
}));

function AnchorNav({ data, headerOffset = 110 }) {
  const classes = useStyles();

  function handleClick(e, id) {
    e.preventDefault();
    let target = document.querySelector(`#${id}`);
    if (target) {
      let offsetPosition = target.offsetTop - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }

  return (
    <nav className={classes.root}>
      {data.map((anchor) => (
        <span key={anchor.id}>
          <a href={`#${anchor.id}`} onClick={(e) => handleClick(e, anchor.id)}>
            <SubdirectoryArrowRightIcon /> <span>{anchor.title}</span>
          </a>
          <br />
        </span>
      ))}
    </nav>
  );
}

export default AnchorNav;
