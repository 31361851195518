import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  blueGrey,
} from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";
import palette from "../../palette";
import fonts from "../../fonts";

const { fontFamily, fontWeight, fontWeightBold, headings, fontURLParam } = fonts;
const base_theme = createTheme();

const _palette = {
  red: {
    main: red["700"],
  },
  pink: {
    main: pink["700"],
  },
  purple: {
    main: purple["700"],
  },
  deepPurple: {
    main: deepPurple["700"],
  },
  indigo: {
    main: indigo["700"],
  },
  blue: {
    main: blue["700"],
  },
  lightBlue: {
    main: lightBlue["700"],
  },
  cyan: {
    main: cyan["700"],
  },
  teal: {
    main: teal["700"],
  },
  green: {
    main: green["700"],
  },
  lightGreen: {
    main: lightGreen["700"],
  },
  lime: {
    main: lime["700"],
  },
  yellow: {
    main: yellow["700"],
  },
  amber: {
    main: amber["700"],
  },
  orange: {
    main: orange["700"],
  },
  deepOrange: {
    main: deepOrange["700"],
  },
  brown: {
    main: brown["700"],
  },
  blueGrey: {
    main: blueGrey["700"],
  },
  ...palette,
};

// A custom theme for this app
const theme = createTheme({
  fontURLParam,
  palette: {
    ..._palette,
  },
  typography: {
    fontFamily,
    fontWeight,
    fontWeightBold,
    headings,
    body1: {
      fontSize: "1.1em",
      overflowWrap: "break-word",
    },
    body2: {
      fontSize: "1.1em",
      lineHeight: 1.5,
    },
    h1: {
      ...headings,
      letterSpacing: "-1px",
      fontSize: "2.6em",
      [base_theme.breakpoints.down("md")]: {
        fontSize: "2.2em",
      },
    },
    h2: {
      ...headings,
      fontSize: "2em",
      "&:not(:first-child)": {
        paddingTop: base_theme.spacing(3),
      },
    },
    h3: {
      ...headings,
      fontSize: "1.6em",
    },
    h4: {
      ...headings,
      fontSize: "1.5em",
    },
    h5: {
      ...headings,
      fontSize: "1.2em",
    },
    h6: {
      ...headings,
      fontSize: "1.1em",
    },
    caption: {
      ...headings,
      fontSize: "1.1em",
      textTransform: "uppercase",
      color: _palette.grey["900"],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1020,
      lg: 1200,
      xl: 1400,
      xxl: 1600,
    },
  },
  overrides: {
    MuiButton: {
      label: {
        fontWeight: fontWeightBold,
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: _palette.grey["700"],
      },
      root: {
        "& small": {
          fontSize: "62%",
        },
      },
    },
    MuiGrid: {
      container: {
        "&.row-grid-container": {
          marginTop: `0 !important`,
          marginBottom: `0 !important`,
        },
      },
      item: {
        width: "100%",
        "&.row-grid-item": {
          paddingTop: `0 !important`,
          paddingBottom: `0 !important`,
        },
      },
    },
    MuiDialog: {
      paper: {
        margin: 0,
      },
    },
    MuiChip: {
      outlined: {
        borderColor: _palette.grey["200"],
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: `0px 2px 4px -1px rgb(0 0 0 / 25%), 0px 4px 5px 0px rgb(0 0 0 / 1%), 0px 1px 10px 0px rgb(0 0 0 / 1%)`,
      },
    },
    MuiPagination: {
      ul: {
        justifyContent: "center",
      },
    },
    MuiCircularProgress: {
      root: {
        margin: "30px auto",
        display: "block",
      },
    },
    MuiListItem: {
      root: {
        lineHeight: 1,
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily,
        fontWeight,
        fontWeightBold,
        textTransform: "none",
        fontSize: "0.86em",
      },
      contained: {
        textAlign: "left",
        marginLeft: 0,
      },
    },
    MuiPopover: {
      root: {
        zIndex: "1302 !important",
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          backgroundColor: _palette.footer.background,
        },
        body: {
          fontFamily,
          fontSize: 16,
          lineHeight: 1.8,
          backgroundColor: _palette.light.main,
          [base_theme.breakpoints.down("lg")]: {
            fontSize: 14,
            lineHeight: 1.6,
          },
          [base_theme.breakpoints.down("md")]: {
            fontSize: 12,
            lineHeight: 1.5,
          },
        },
      },
    },
  },
});

export default theme;

export const searchFieldStyles = {
  search: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(3.5),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2.5),
    },
  },
  searchInput: {
    backgroundColor: "white",
    width: 340,
    border: "none",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    fontSize: "1.2em",
    padding: theme.spacing(0, 2),
    height: theme.spacing(7),
    lineHeight: theme.spacing(7),
    [theme.breakpoints.down("md")]: {
      width: 220,
      height: theme.spacing(5),
      lineHeight: theme.spacing(4),
    },
  },
  searchButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    fontSize: "1em",
    width: 150,
    padding: theme.spacing(0, 4),
    height: theme.spacing(7),
    lineHeight: theme.spacing(7),
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      height: theme.spacing(5),
      lineHeight: theme.spacing(4),
      width: 100,
    },
  },
};
